import { IonModal, useIonToast } from "@ionic/react";
import { Images } from "../../images";
import { IoCloseSharp } from "react-icons/io5";

const CallJoinedUsers = ({ activeUserDropdown,dropDownUserData,setActiveUserDropdown }) => {
  return (
    <IonModal className="w-60 calluserlist " isOpen={activeUserDropdown}>
         <div
          className="w-100 flex-space-between p-5"
         
        >
            <span className="font-12 text-center">Call Users</span>
          <IoCloseSharp  onClick={()=>setActiveUserDropdown(false)}/>
        </div> 
     <div className="user-scroll">

    
        {dropDownUserData.length&&dropDownUserData?.map((userObj, index) => {
          return (
            <div
              key={index}
              className="custom-box-shadow drop-down-menu font-12  mb-5"
            >
              {userObj}
            </div>
          );
        })}
         </div>
     
    </IonModal>
  );
};

export default CallJoinedUsers;
