import { useState, useEffect } from "react";

import MeetingsHeader from "../meetings/MeetingsHeader";
import { GET_OFFLINE_CLIENTS } from "../../config/endpoints";
import { call } from "../../config/axios";
import Table from "../../common/Table";
import { sumOfData } from "../../utils";
import SettlementData from "../settlement/SettlementData";
import { VscAdd } from "react-icons/vsc";
import AddbuyerPopup from "../popups/AddbuyerPopup";
import Tickets from "./Tickets";
import Stock from "./Stock";
import ProfitLoss from "./ProfitLoss";
import BuyPackage from "../popups/BuyPackage";
import { FaAngleDown } from "react-icons/fa6";

const LiveLineStreamReport = () => {
  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");
  const [settlementStatus, setSettlementStatus] = useState(false);
  const [buyerPopup, setBuyerPopup] = useState(false);
  const [settlementData, setSettlementData] = useState([]);
  const [selectedAction, setSelectedAction] = useState("Buyers");
  const [purchaseClick, setPurchaseClick] = useState(true);
  const [liveDropDown, setLiveDropDown] = useState(false);
  const [buyPackage, setBuyPackage] = useState(false);
  const [liveStreamDropDown, setliveStreamDropDown] = useState();
  const [selectedOption, setSelectedOption] = useState(
    "Live Stock Own"
  );
  const [puchaseSales,setPurchaseSales]=useState("Purchase")

  const toggleDropdown = () => setliveStreamDropDown(!liveStreamDropDown);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setliveStreamDropDown(false);
  };

  const actions = [
    { header: "buyers", name: "Buyers" },
    { header: "tickets", name: "Tickets" },
    { header: "stock", name: "Stock" },
    { header: "p_s_report", name: "P&S Report" },
  ];
  const TicketActions = [{ name: "Purchase" }, { name: "Sale" }];

  const handleLiveDropDown = () => {
    setLiveDropDown(true);
  };

  const getSettlementData = async () => {
    await call(GET_OFFLINE_CLIENTS, {
      register_id,
      account_role,
    })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setSettlementData(res?.data?.data);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    getSettlementData();
  }, [settlementStatus]);

  const userDetailsProfitLoss = [
    {
      client_name: (
        <div className="settlemt-statement-client-data">
          <div>Sai Offline master </div>
          <div>Hyderabad</div>
        </div>
      ),
      L_Streaming: (
        <div className="settlemt-statement-client-data">
          <div>
            30 days- <span className="clr-orng">100(25%)</span>{" "}
          </div>
          <div>
            90 days- <span className="clr-orng">50 (25%)</span>{" "}
          </div>
          <div>
            180 days- <span className="clr-orng">25(30%)</span>{" "}
          </div>
        </div>
      ),
      Line: (
        <div className="settlemt-statement-client-data">
          <div>
            30 days-<span className="clr-orng">100(25%)</span>{" "}
          </div>
          <div>
            90 days-<span className="clr-orng">50(25%)</span>{" "}
          </div>
          <div>
            180 days-<span className="clr-orng">25(30%)</span>{" "}
          </div>
        </div>
      ),
      P_S: (
        <div className="settlemt-statement-client-data">
          <div className="clr-white">P:100000 </div>
          <div className="clr-orng">S:100000 </div>
          <div className="clr-green">P/L:100000 </div>
        </div>
      ),
      blank: (
        <div className="settlemt-statement-client-data flex-centre">
          <div className="action-btn-shadow action-btn br-10 p-5 flex-center pink-blue-text">
            Edit
          </div>
          <div className="action-btn-shadow action-btn br-10 p-5 flex-center pink-blue-text">
            B
          </div>
        </div>
      ),
    },
    {
      client_name: (
        <div className="settlemt-statement-client-data">
          <div>Sai Offline master </div>
          <div>Hyderabad</div>
        </div>
      ),
      L_Streaming: (
        <div className="settlemt-statement-client-data">
          <div>
            30 days- <span className="clr-orng">100(25%)</span>{" "}
          </div>
          <div>
            90 days- <span className="clr-orng">50 (25%)</span>{" "}
          </div>
          <div>
            180 days- <span className="clr-orng">25(30%)</span>{" "}
          </div>
        </div>
      ),
      Line: (
        <div className="settlemt-statement-client-data">
          <div>
            30 days-<span className="clr-orng">100(25%)</span>{" "}
          </div>
          <div>
            90 days-<span className="clr-orng">50(25%)</span>{" "}
          </div>
          <div>
            180 days-<span className="clr-orng">25(30%)</span>{" "}
          </div>
        </div>
      ),
      P_S: (
        <div className="settlemt-statement-client-data">
          <div className="clr-white">P:100000 </div>
          <div className="clr-orng">S:100000 </div>
          <div className="clr-green">P/L:100000 </div>
        </div>
      ),
      blank: (
        <div className="settlemt-statement-client-data flex-centre">
          <div className="action-btn-shadow action-btn br-10 p-5 flex-center pink-blue-text">
            Edit
          </div>
          <div className="action-btn-shadow action-btn br-10 p-5 flex-center pink-blue-text">
            B
          </div>
        </div>
      ),
    },
    {
      client_name: (
        <div className="settlemt-statement-client-data">
          <div>Sai Offline master </div>
          <div>Hyderabad</div>
        </div>
      ),
      L_Streaming: (
        <div className="settlemt-statement-client-data">
          <div>
            30 days- <span className="clr-orng">100(25%)</span>{" "}
          </div>
          <div>
            90 days- <span className="clr-orng">50 (25%)</span>{" "}
          </div>
          <div>
            180 days- <span className="clr-orng">25(30%)</span>{" "}
          </div>
        </div>
      ),
      Line: (
        <div className="settlemt-statement-client-data">
          <div>
            30 days-<span className="clr-orng">100(25%)</span>{" "}
          </div>
          <div>
            90 days-<span className="clr-orng">50(25%)</span>{" "}
          </div>
          <div>
            180 days-<span className="clr-orng">25(30%)</span>{" "}
          </div>
        </div>
      ),
      P_S: (
        <div className="settlemt-statement-client-data">
          <div className="clr-white">P:100000 </div>
          <div className="clr-orng">S:100000 </div>
          <div className="clr-green">P/L:100000 </div>
        </div>
      ),
      blank: (
        <div className="settlemt-statement-client-data flex-centre">
          <div className="action-btn-shadow action-btn br-10 p-5 flex-center pink-blue-text">
            Edit
          </div>
          <div className="action-btn-shadow action-btn br-10 p-5 flex-center pink-blue-text">
            B
          </div>
        </div>
      ),
    },
  ];

  const columns = [
    {
      header: "client_name",
      name: "Admin Name",
    },
    { header: "L_Streaming", name: "Live Streaming %" },
    { header: "Line", name: "Line" },
    { header: "P_S", name: "P&S" },
    { header: "blank", name: "Action" },
  ];

  return (
    <>
      <MeetingsHeader heading="Live/Line Streaming" />
      <div className="offline-page-container">
        <div className="action-report br-10 py-10">
          <div className="action-alignment w-100">
            {actions.map((item, index) => (
              <div
                key={index}
                className={`p-5 br-10 action-btn font-13 action-btn-shadow w-20 flex-center ${
                  selectedAction === item.name ? "clr-green" : ""
                }`}
                onClick={() => setSelectedAction(item.name)}
              >
                {item.name}
              </div>
            ))}
          </div>

          <div className="action-line"></div>
          {selectedAction === "Buyers" && (
            <div className="flex-space-between ">
              <div className="p-10">Buyers</div>
              <div
                className=" p-10 br-10 action-btn font-13 flex-space-between  w-30"
                onClick={() => setBuyerPopup(true)}
              >
                Add Buyers
                <div>
                
                  <VscAdd className="add-icon" />
                </div>
              </div>
            </div>
          )}
          {selectedAction === "Tickets" && (
            <div className="flex-space-between">
              <div className="p-10">Tickets</div>
              <div className="w-50 flex-space-between">
                <div
                  className={` p-10 br-10 action-btn font-13 flex-center w-50 ${
                    purchaseClick === true ? "clr-green" : ""
                  }`}
                  onClick={() => setPurchaseClick(true)}
                >
                  Purchase
                </div>
                <div
                  className={` p-10 br-10 action-btn font-13 flex-center w-50 ${
                    purchaseClick === false ? "clr-green" : ""
                  } `}
                  onClick={() => setPurchaseClick(false)}
                >
                  Sales
                </div>
              </div>
            </div>
          )}
          {selectedAction === "Stock" && (
            <div className="flex-space-between">
              <div className="p-10">Stock</div>
              <div className="w-60 flex-space-between">
                <div className="p-10 br-10 action-btn font-13 flex-center w-100">
                  <div
                    className="clr-green flex-space-between w-100 "
                    onClick={toggleDropdown}
                  >
                    {selectedOption}
                    <FaAngleDown />
                 
                  </div>

                  {liveStreamDropDown && (
                    <div  className="live-stream-stock action-btn action-btn-shadow br-10 border-white ">
                      <div
                       className="p-10 white-border"
                        onClick={() =>
                          handleOptionClick("Live stock own")
                        }
                      >
                       Live Stock own
                      </div>
                      <div
                       className="p-10"
                        onClick={() => handleOptionClick("Line Stock own")}
                      >
                        Line Stock own
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={` p-10 br-10 action-btn font-13 flex-center w-50 `}
                >
                  <div onClick={() => setBuyPackage(true)}> Buy</div>
                </div>
              </div>
            </div>
          )}

          {selectedAction === "P&S Report" && (
            <div className="flex-space-between">
              <div className="p-10">Purchase & Sale Report</div>
              <div className="w-50 flex-space-between">
                <div
                  className={` p-10 br-10 action-btn font-13 flex-center w-50 ${
                    purchaseClick === true ? "clr-green" : ""
                  }`}
                  onClick={() => {
                    setPurchaseClick(true)
                    setPurchaseSales("Purchase")
                  } }
                >
                  Purchase
                </div>
                <div
                  className={` p-10 br-10 action-btn font-13 flex-center w-50 ${
                    purchaseClick === false ? "clr-green" : ""
                  } `}
                  onClick={() => {
                    setPurchaseClick(false)
                    setPurchaseSales("Sales")
                  } }
                >
                  Sales
                </div>
              </div>
            </div>
          )}
        </div>
        {selectedAction === "Buyers" && (
          <>
            <div className="p-10 font-weight-600">
              <span className="clr-orng">Super Admin {">"}</span> Srinivas{" "}
            </div>
            <div className="scrolling-settlement">
              <Table columns={columns} data={userDetailsProfitLoss} />
            </div>
            <table className="w-100 account-summary-main-container mt-5">
              <tfoot>
                <tr>
                  <th>P</th>
                  <th>800000</th>
                  <th>S</th>
                  <th className="clr-orng br-10 ">500000</th>
                  <th>P/L</th>
                  <th className="clr-green">500000</th>
                </tr>
              </tfoot>
            </table>
          </>
        )}
        {selectedAction === "Tickets" && (
          <Tickets purchaseClick={purchaseClick} />
        )}
        {selectedAction === "Stock" && <Stock  selectedOption={selectedOption}/>}
        {selectedAction === "P&S Report" && <ProfitLoss puchaseSales={puchaseSales} />}
      </div>
      <AddbuyerPopup buyerPopup={buyerPopup} setBuyerPopup={setBuyerPopup} />
      <BuyPackage setBuyPackage={setBuyPackage} buyPackage={buyPackage} />
    </>
  );
};

export default LiveLineStreamReport;
