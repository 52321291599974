export const Images = {
  We2call: "./assets/we_2_call_logo.png",
  We2CallLite: "./assets/we_2_Lite.png",
  LoginIcon: "./assets/login_icon.png",
  DarkModeColour: "./assets/dark_mode_colour.png",
  Settings: "./assets/Settings.png",
  Share: "./assets/Share.png",
  UserManagement: "./assets/Illustration_02.png",
  SportsManagement: "./assets/Illustration_03.png",
  AddAccount: "./assets/add_Account.png",
  EditProfile: "./assets/edit_Profiles_Icon.png",
  Logout: "./assets/log_Out.png",
  ResetPassword: "./assets/reset_Password.png",
  BackArrow: "./assets/back_arrow.png",
  CalenderImg: "./assets/calender_icon.png",
  PlayIcon: "./assets/play_icon.png",
  DeleteIcon: "./assets/delete_icon.png",
  Banner: "./assets/Banner_illustration.png",
  UserManagement: "./assets/Illustration_02.png",
  SportsManagement: "./assets/Illustration_03.png",
  Banner: "./assets/Banner_Illustration.png",
  UserManagement: "./assets/Illustration_02.png",
  SportsManagement: "./assets/Illustration_03.png",
  DeclareManImage: "./assets/declare_man_img.png",
  ThumbsUpImage: "./assets/thumbs_up_img.png",
  MatchPlace: "./assets/location.png",
  Stadium: "./assets/stadium.png",
  Calender: "./assets/calender_icon",
  Clock: "./assets/clock.png",
  MobilePhone: "./assets/mobile_phone.png",
  TermsVectorImage: "./assets/vector_sector_img.png",
  handIllus: "./assets/hand_illus.png",
  paymentSettlement: "./assets/payment_settlement.png",
  paymentSettled: "./assets/payment_settled.png",
  success: "./assets/success.png",
  Coin: "./assets/coin.png",
  DiamondPackage: "./assets/diamond_package.png",
  Diamond: "./assets/diamond.png",
  Discount: "./assets/discount_02.png",
  GiftBox: "./assets/gift_box.png",
  GoldMedal: "./assets/gold_medal.png",
  Crown: "./assets/crown.png",
  GoldPackage: "./assets/gold_package.png",
  SilverMedal: "./assets/silver_medal.png",
  SilverPackage: "./assets/silver_package.png",
  SmallDiamond: "./assets/small_diamond.png",
  StandardPackage: "./assets/standard_package.png",
  CallHistory: "./assets/call_history.png",
  CallSatement: "./assets/call_statement.png",
  MatchStatement: "./assets/match_statement.png",
  OnepageReport: "./assets/one_page_report.png",
  Upgrade: "./assets/upgrade.png",
  Settlement: "./assets/settlement.png",
  Phone: "./assets/phone.png",
  Desktop: "./assets/desktop.png",
  OffersMessage: "./assets/offers_message.png",
  CongratsImage: "./assets/congrats_image.png",
  PDFBooking: "./assets/pdf_booking.png",
  SuitcaseImage: "./assets/suitcase_image.png",
  AirPlaneBG: "./assets/airplane_bg.png",
  CricketBanner: "./assets/cricket_banner.png",
  FootBallBanner: "./assets/football_banner.png",
  CasinoBanner: "./assets/casino_banner.png",
  EntBanner: "./assets/man_suitcase.png",
  DiscountIcon: "./assets/discount_icon.png",
  TourGiftBox: "./assets/tour_gift_box.png",
  DogImage: "./assets/dog.jpg",
  PrivacyPolicyImg: "./assets/privacy-policy-img.png",
  CallingProfileImage: "./assets/calling_profile_image.png",
  CSKImage: "./assets/CSKImage.png",
  MIImage: "./assets/mumbaiIndiansImg.png",
  CricketBat: "./assets/cricketBat.png",
  CricketBall: "./assets/cricketBall.png",
  callWaiting: "../assets/Calling_waiting.png",
  userManagerWhite: "../assets/User-Management-White.png",
  userManageryellow: "../assets/User-Manage-Yellow.png",
  //added//
  DarkBGImage: "/assets/dark_back_ground.png",
  ViratImage: "./assets/virat_image.png",
  ViratImage02: "./assets/virat_image_02.png",
  NoChatImage: "./assets/no_chat_img.png",
  DhoniImage: "./assets/dhoni_image.png",
  DhoniImage02: "./assets/dhoni_image_02.png",
  RohitImage: "./assets/rohit_image.png",
  GreenDoubleTick: "./assets/green_double_tick.png",
  qr_code: "./assets/qr_code.png",
};
// export const ImageBaseUrl = "https://we2-call-images.s3.us-east-2.amazonaws.com"; // ohio
export const ImageBaseUrl =
  "https://we2-call-images-singapore.s3.ap-southeast-1.amazonaws.com"; // singpore

  // export const UpdatedBaseUrl =
  // "https://we2-call-images.s3.us-east-2.amazonaws.com"; 
