import { useEffect, useState } from "react";
import MeetingsHeader from "./MeetingsHeader";
import { Link } from "react-router-dom";
import { BsArrowDown } from "react-icons/bs";
import { RiEditLine } from "react-icons/ri";
import { FaCalendarDays } from "react-icons/fa6";
import { MdAccessTime } from "react-icons/md";
import FancyThankYouPopup from "../fancypopups/FancyThankYouPopup";
import EditPopup from "../popups/EditPopup";
import {
  CREATE_MEETING,
  GET_ADMIN_PACKAGES,
  GET_ALL_CLIENTS,
  GET_ALL_MEETINGS,
  GET_OFFLINE_ALL_MATCHES,
  UPDATE_MEETING,
} from "../../config/endpoints";
import { call } from "../../config/axios";
import { IonCol, IonRow, useIonToast } from "@ionic/react";
import { useHistory } from "react-router";
import SelectPackageType from "../fancypopups/SelectPackageType";
import Select from "react-select";
import moment from "moment";
import CallJoinedUsers from "../popups/CallJoinedUsers";

function CallManagement() {
  const register_id = localStorage?.getItem("register_id");
  const account_role = localStorage?.getItem("account_role");
  const history = useHistory();
  const [activeUserDropdown, setActiveUserDropdown] = useState(false);
  const meetingsType = ["Professional", "Personal"];
  const [activeIndex, setActiveIndex] = useState(0);
  const [listOfUsers, setListOfUsers] = useState([]);
  const [callManagementSubmitPopup, setCallManagementSubmitPopup] =
    useState(false);
  const [selectPackagePopup, setSelectPackagePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [joinPopup, setJoinPopup] = useState(false);
  const [meetingInput, setMeetingInput] = useState({});
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [adminSubscription, setAdminSubscription] = useState({});
  const [selectedMeeting, setSelectedMeeting] = useState("");
  const [selectedOptions, setSelectedOptions] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [matchesData, setMatchesData] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const UserDisplayData = "Created New Meeting Successfully";
  const packageLimits =
    adminSubscription &&
    adminSubscription?.length > 0 &&
    adminSubscription?.map((item) => item?.package_limits);

  const handleMeetingTypeButton = (value) => {
    if (activeIndex !== value) {
      setMeetingInput({});
      setSelectedMeeting("");
      setMeetingList();
      setSelectedOptions([]);
    }
    setActiveIndex(value);
  };
  const [present] = useIonToast();
  const presentToast = ({ message, color = "danger" }) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: color,
    });
  };
  const [activeCallCreationIndex, setActiveCallCreationIndex] = useState(0);

  const handleCallCreateButton = (index, call) => {
    setActiveCallCreationIndex(index);
    if (index === 1) {
      setHideUsers("hide");
    } else if (index === 0) {
      setHideUsers("audio");
    } else {
      setHideUsers("audio-video");
    }
  };

  const callCreationList = ["hide", "video+Audio"];
  const [hideUsers, setHideUsers] = useState("audio");
  const [dropDownUserData, setDropDownUserData] = useState(0);

  const filteredCallCreationList =
    meetingsType[activeIndex] === "Professional"
      ? callCreationList.filter((call) => call === "hide")
      : callCreationList.filter((call) => call !== "hide");
  const handleUserDropdown = (data) => {
    setActiveUserDropdown((prevState) => !prevState);
    setDropDownUserData(data);
  };
  const [usersListDropdown, setUsersListDropdown] = useState([]);
  const [showUsersListDropdown, setShowUsersListDropdown] = useState("");
  const handleUsersListSelect = (params) => {
    const findIndex = usersListDropdown.findIndex(
      (obj) => obj.register_id == params.register_id
    );
    if (findIndex) {
      const updatedUsersListDropdown = [...usersListDropdown];
      updatedUsersListDropdown.push(params);
      setUsersListDropdown(updatedUsersListDropdown);
      setShowUsersListDropdown(false);
    }
  };

  const onChange = (e) => {
    setMeetingInput({ ...meetingInput, [e.target.name]: e.target.value });
  };
  const handleOpenEditPopup = (data) => {
    setEditPopup(!editPopup);
    setSelectedMeeting(data);
  };

  const getSelectedIndex = (selectedMeeting) => {
    if (selectedMeeting?.isAllUserAccessStatus) {
      setActiveCallCreationIndex(1);
    } else if (selectedMeeting?.video_call_type) {
      setActiveCallCreationIndex(2);
    } else {
      setActiveCallCreationIndex(0);
    }
  };

  const onEditClick = async (flag) => {
    setEditPopup(!editPopup);
    if (flag) {
      const obj = {
        ...selectedMeeting,
        date: moment(selectedMeeting.date).format("DD-MM-YYYY"),
        time: moment(selectedMeeting.time).format("hh:mm:ss A"),
      };
      const list = (
        listOfUsers &&
        listOfUsers?.length > 0 &&
        listOfUsers?.filter((item) =>
          selectedMeeting?.meetingUserIds?.includes(item.register_id)
        )
      )?.map((item) => {
        return { value: item?.register_id, label: item?.user_name };
      });
      setSelectedOptions(list);
      const packageList =
        adminSubscription &&
        adminSubscription?.length > 0 &&
        adminSubscription
          ?.filter((item) => selectedMeeting?.package_id === item?.package_id)
          .map((item) => {
            return {
              value: item?.package_id,
              label: (
                <IonRow className="font-14">
                  <IonCol size="5" className="yellow-clr">
                    {item?.package_name} -
                    {item?.package_duration === "monthly" && "(M)"}
                    {item?.package_duration === "yearly" && "(Y)"}
                  </IonCol>
                  <IonCol size="3.5">
                    Dur: {item?.package_limits?.duration}
                  </IonCol>
                  <IonCol size="3.5">
                    Users: {item?.package_limits?.members}
                  </IonCol>
                </IonRow>
              ),
              members: item?.package_limits?.members,
            };
          });
      setSelectedPackages(packageList);
      const activeMeetingIndex =
        selectedMeeting?.meeting_type == "Professional" ? 0 : 1;
      setActiveIndex(activeMeetingIndex);
      setMeetingList({
        value: selectedMeeting?.match_id,
        label: (
          <IonRow className="font-14">
            <IonCol size="4.5">
              <div>{selectedMeeting.match_name}</div>
              <div className="mt-5">{selectedMeeting.event_name}</div>
            </IonCol>
            <IonCol size="4.5">
              <div>{moment(selectedMeeting.date).format("DD-MM-YYYY")}</div>
              <div className="mt-5">{selectedMeeting.time}</div>
            </IonCol>
            <IonCol size="3">
              <div>{selectedMeeting.sport_name}</div>
            </IonCol>
          </IonRow>
        ),
      });
      await getSelectedIndex();
      setMeetingInput({ ...meetingInput, ...obj });
    }
  };

  const handleOpenJoinPopup = (data, isNotCreated) => {
    const register_id = localStorage.getItem("register_id");
    register_id == data.p_id
      ? localStorage.setItem("isAdminMeeting", true)
      : localStorage.setItem("isAdminMeeting", false);
    if (isNotCreated) {
      const newTab = window.open(`/upjoin/${data?.meeting_id}`, "_blank");
      if (newTab) {
        newTab.focus();
      }
      //  history.push(`/upjoin/${data?.meeting_id}`);
    } else {
      const newTab = window.open(`/join/${data?.meeting_id}`, "_blank");
      if (newTab) {
        newTab.focus();
      }
      //  history.push(`/join/${data?.meeting_id}`);
    }

    // const serializedData = customStringify(data);
    // if (isNotCreated) {
    //   history.push(`/meeting/${data?.meeting_id}`);
    //   localStorage.setItem("isAdminMeeting", false);
    //   return;
    // }

    // console.log('data',data)
    //   const package_data = adminSubscription &&
    //    adminSubscription?.length > 0 &&
    //     adminSubscription
    //       ?.filter((item) => data?.package_id === item?.package_id);
    //       let duration = package_data[0]?.package_limits?.duration;
    //       const [hoursStr, minutesStr] = duration?.split(":");
    //       const hours = parseInt(hoursStr);
    //       const minutes = parseInt(minutesStr);
    //       const totalMinutes = hours * 60 + minutes;
    //       console.log('package_data',package_data)
    // if (totalMinutes < 0) {
    //   presentToast({ message: "Insuffient package hrs, Please upgarde hrs!" });
    //   return;
    // }
    // if (package_data[0].package_limits?.members < data.meetingUserIds?.length) {
    //   presentToast({ message: "Users Limit exceeded, Please Update" });
    //   return;
    // }
    // if (package_data[0]) {
    //   const currentTime = new Date();
    //   const startTimeStr = currentTime.toISOString();
    //   localStorage.setItem('startTime', startTimeStr);
    //    localStorage.setItem("meeting_id", data?.meeting_id);
    //   localStorage.setItem("totalMinutes", totalMinutes);
    //   localStorage.setItem("isAdminMeeting", true);
    // history.push(`/meeting/${data?.meeting_id}`,  { meetingData: serializedData });

    // } else {
    //   presentToast({
    //     message: "you don't have any subscription, Please upgarde !",
    //   });
    // }
  };

  function customStringify(obj, seen = new Set()) {
    if (typeof obj === "object" && obj !== null) {
      if (seen.has(obj)) {
        return "[Circular]";
      }
      seen.add(obj);
    }
    return JSON.stringify(obj, (key, value) =>
      typeof value === "object" && value !== null
        ? customStringify(value, seen)
        : value
    );
  }

  const getAdminPackages = async () => {
    await call(GET_ADMIN_PACKAGES, { register_id })
      .then((res) => {
        const data = res?.data?.data;
        if (data) {
          const adminSubscription = [];
          data?.forEach((item) => {
            if (!item.bulk_subscriptions) {
              adminSubscription.push(item.subscriptions);
            }
          });
          const flattenedAdminSubscription = adminSubscription.flat();
          setAdminSubscription(flattenedAdminSubscription);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAdminPackages();
  }, []);

  const handleSubmitButton = async () => {
    if (!selectedPackages?.value) {
      presentToast({ message: "Please select package" });
      return;
    }
    console.log(selectedPackages, "selectedPackages");
    console.log(adminSubscription, "adminSubscription");
    const selectedmeetingPackage = adminSubscription.find(
      (obj) => obj.package_id == selectedPackages?.value
    );
    if (
      selectedmeetingPackage?.package_limits?.personal_meetings < 1 &&
      meetingsType[activeIndex] !== "Professional"
    ) {
      presentToast({
        message: "personal meetings not avialable in this package",
      });
      return;
    }

    let result;
    switch (activeCallCreationIndex) {
      case 2:
        result = "audio-video";
        break;
      case 1:
        result = "audio";
        break;
      default:
        result = "hide";
    }
    const payload = {
      ...meetingInput,
      meetingUserIds: selectedOptions?.map((obj) => obj.value),
      register_id,
      meeting_type: meetingsType[activeIndex],
      video_call_type:
        meetingsType[activeIndex] === "Professional" ? "hide" : "audio-video",
      audio_call_type:
        meetingsType[activeIndex] === "Professional" ? "hide" : "audio-video",
      package_id: selectedPackages?.value,
      isAllUserAccessStatus:
        meetingsType[activeIndex] === "Professional" ? false : true,
    };
    setIsProcessing(true);
    const url = selectedMeeting ? UPDATE_MEETING : CREATE_MEETING;
    delete payload?.eventName;

    console.log(payload, "paaylooad");
    await call(url, payload)
      .then((res) => {
        if (res.data.statusCode == 200) {
          setMeetingInput({});
          setSelectedMeeting({});
          setSelectedPackages({});
          setSelectedOptions([]);
          setMeetingList();
          setIsProcessing(false);
          getUpcomingMeetings();
          presentToast({ message: res?.data?.message, color: "success" });
        } else {
          setIsProcessing(false);
          presentToast({ message: res.data?.data?.message });
        }
      })
      .catch((err) => {
        presentToast({ message: "Something went wrong!" });
        setIsProcessing(false);
        console.log(err);
      });
  };

  const getAdminUsersList = async () => {
    await call(GET_ALL_CLIENTS, { register_id, account_role })
      .then((res) => {
        console.log(res);
        const resp = res?.data?.data;
        setListOfUsers(
          resp?.length > 0
            ? resp
                ?.filter((obj) => obj?.active == true)
                .map((obj) => {
                  return {
                    user_name: obj.user_name,
                    register_id: obj.register_id,
                  };
                })
            : []
        );
      })
      .catch((err) => console.log(err));
  };
  const getUpcomingMeetings = async () => {
    await call(GET_ALL_MEETINGS, { register_id })
      .then((res) => {
        const result = res?.data?.data?.filter(
          (meeting) => meeting?.completed_status === false
        );
        setUpcomingMeetings(result);
      })
      .catch((err) => console.log(err));
  };

  const getAllMatches = async () => {
    await call(GET_OFFLINE_ALL_MATCHES, {
      register_id,
      account_role,
    })
      .then(async (res) => {
        let result = res?.data?.data;
        const temp = result?.liveMatches?.filter(
          (i) => i.match_declared !== "Y"
        );
        await getCompanyMatches(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCompanyMatches = async (data) => {
    await call(GET_OFFLINE_ALL_MATCHES, {
      register_id: "company",
      account_role,
    })
      .then((res) => {
        let result = res?.data?.data;
        console.log(result, "result");
        const temp =
          result === undefined
            ? []
            : result?.liveMatches?.filter(
                (i) => i.match_declared !== "Y" || ""
              );
        setMatchesData([...data, ...temp]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAllMatches();
    };
    fetchData();
  }, []);

  useEffect(() => {
    getAdminUsersList();
    getUpcomingMeetings();
  }, []);

  const optionList =
    listOfUsers.length > 0 &&
    listOfUsers.map((item) => {
      return { value: item?.register_id, label: item?.user_name };
    });

  const handleSelect = (data) => {
    if (!selectedPackages || selectedPackages.length === 0) {
      presentToast({ message: "Please Select Package" });
      setSelectedOptions("");
      return;
    }
    if (data?.length <= selectedPackages?.members) {
      setSelectedOptions(data);
    } else {
      presentToast({ message: "You Reached Maximum Limit Of Users" });
    }
  };

  const [meetingList, setMeetingList] = useState();
  const meetingOptionsList = matchesData?.map((item) => {
    return {
      value: item?.match_id,
      label: (
        <IonRow className="font-14">
          <IonCol size="4.5">
            <div>{item?.match_name}</div>
            <div className="mt-5">{item?.series_name}</div>
          </IonCol>
          <IonCol size="4.5">
            <div>{moment(item?.date).format("DD-MM-YYYY")}</div>
            <div className="mt-5">{item?.time}</div>
          </IonCol>
          <IonCol size="3">
            <div>{item?.sport_name}</div>
          </IonCol>
        </IonRow>
      ),
    };
  });

  const adminPackages =
    adminSubscription?.length > 0
      ? adminSubscription.map((item) => {
          return {
            value: item?.package_id,
            label: (
              <IonRow className="font-14">
                <IonCol size="5" className="yellow-clr">
                  {item?.package_name} -
                  {item?.package_duration === "monthly" && "(M)"}
                  {item?.package_duration === "yearly" && "(Y)"}
                </IonCol>
                <IonCol size="3.5">
                  {console.log("duration", item?.package_limits)}
                  Dur: {item?.package_limits?.duration}
                </IonCol>
                <IonCol size="3.5">
                  Users: {item?.package_limits?.members}
                </IonCol>
              </IonRow>
            ),
            members: item?.package_limits?.members,
          };
        })
      : [];

  const handlePackageSelection = (data) => {
    setSelectedPackages(data);
  };

  const handleMatchSelect = (data) => {
    setMeetingList(data);
    const selectedManagementMeeting = matchesData.find(
      (obj) => obj.match_id == data.value
    );
    setMeetingInput({
      ...meetingInput,
      event_name: selectedManagementMeeting?.series_name,
      sport_name: selectedManagementMeeting?.sport_name,
      match_name: selectedManagementMeeting?.match_name,
      date: moment(selectedManagementMeeting?.matchTimeStamp).format(
        "DD-MM-YYYY"
      ),
      time: moment(selectedManagementMeeting?.matchTimeStamp).format(
        "hh:mm:ss A"
      ),
      match_id: selectedManagementMeeting.match_id,
    });
  };

  const upcomingMeetingsData =
    upcomingMeetings?.length >= 0 &&
    upcomingMeetings
      ?.filter((obj) => obj.p_id == register_id)
      ?.map((item) => {
        const dateTimeString = `${item.date} ${item.time}`;
        const timestamp = new Date(dateTimeString).getTime();
        item.timestamp = timestamp;
        return item;
      })
      ?.sort((a, b) => b.timestamp - a.timestamp)
      ?.map((obj) => {
        const meetingUserData =
          (listOfUsers?.length &&
            listOfUsers?.filter(
              (item) =>
                obj?.meetingUserIds?.includes &&
                obj?.meetingUserIds?.includes(item.register_id)
            )) ||
          [];
        return {
          ...obj,
          urs: (
            <div>
              {obj.createdBy}
              <br />
              {obj?.meeting_type}
            </div>
          ),
          eventName: (
            <div>
              <div>{obj.match_name}</div>
              <div>{obj.event_name}</div>
              <div>{moment(obj.date).format("DD-MM-YYYY")}</div>
              <div>{obj.time}</div>
            </div>
          ),
          user: meetingUserData?.map((obj) => obj.user_name),
          action: "edit",
        };
      });

  const ulMeetingsData =
    (upcomingMeetings?.length >= 0 &&
      upcomingMeetings
        // ?.filter((obj) => {
        //   const timeCreate = new Date().getTime();

        //   console.log(moment(timeCreate).format("DD-MM-YYYY"),'timeCreate')
        //   console.log(moment(obj.date).format("DD-MM-YYYY"),'obj.date')
        //   console.log(register_id,'register_id')
        //   console.log(obj.p_id,'p_id')
        //   return (
        //     obj.p_id !== register_id &&
        //     obj.date >= moment(timeCreate).format("DD-MM-YYYY")
        //   );
        // })
        ?.map((item) => {
          console.log(item, "ittem");
          const dateTimeString = `${item.date} ${item.time}`;
          const timestamp = new Date(dateTimeString).getTime();
          item.timestamp = timestamp;
          return item;
        })
        ?.sort((a, b) => b.timestamp - a.timestamp)
        ?.map((item) => {
          const dateTimeString = `${item.given_time_stamp}`;
          const timestamp = new Date(dateTimeString).getTime();
          item.timestamp = timestamp;
          return item;
        })
        ?.sort((a, b) => b.timestamp - a.timestamp)
        ?.map((obj) => {
          return {
            ...obj,
            ul: (
              <div>
                {obj.createdBy}
                <br />
                {obj?.meeting_type}
              </div>
            ),
            eventName: (
              <div>
                <div>{obj.event_name}</div>
                <div>{obj?.match_name}</div>
                <div>{moment(obj.created_time_stamp).format("DD-MM-YYYY")}</div>
                <div>{obj.time}</div>
              </div>
            ),
            user: localStorage.getItem("user_name"),
            action: "--",
          };
        })) ||
    [];
  // const ulMeetingsData =
  //   (upcomingMeetings?.length > 0 &&
  //     upcomingMeetings
  //       ?.filter((obj) => {
  //         const timeCreate = new Date().getTime();
  //         const meetingDate = new Date(obj.date).getTime();
  //         return obj.p_id !== register_id && meetingDate >= timeCreate;
  //       })
  //       ?.map((item) => {
  //         const dateTimeString = `${item.date} ${item.time}`;
  //         const timestamp = new Date(dateTimeString).getTime();
  //         item.timestamp = timestamp;
  //         return item;
  //       })
  //       ?.sort((a, b) => b.timestamp - a.timestamp)
  //       ?.map((obj) => {
  //         return {
  //           ...obj,
  //           ul: obj.createdBy,
  //           eventName: (
  //             <div>
  //               <div>{obj.event_name}</div>
  //               <div>{obj?.match_name}</div>
  //               <div>
  //                 {new Date(obj.created_time_stamp).toLocaleDateString()}
  //               </div>
  //               <div>{obj.time}</div>
  //             </div>
  //           ),
  //           user: localStorage.getItem("user_name"),
  //           action: "--",
  //         };
  //       })) ||
  //   [];
  console.log(upcomingMeetings, "upcomingMeetings");
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid yellow",
      color: state.isSelected ? "white" : "white",
      background: state.isSelected ? "#0c123e" : "#0c123e",
    }),
  };
  return (
    <div>
      <MeetingsHeader heading={"Call Management"}></MeetingsHeader>
      <div className="we-2-call-main-section">
        <div className="custom-box-shadow  meetings-details-div mt-10 position-relative">
          <div className="flex-row flex-space-between p-10">
            <div className=" custom-box-shadow upcoming-meetings-div flex-start font-16 w-60 p-10">
              Upcoming Meetings
            </div>
            <div className="flex-center seeall-button h-30p w-30 p-5 button-box-shadow meetings-time-text w-20 font-14">
              See All
            </div>
          </div>
          <div className="hr-line mt-5"></div>
          <div className="flex-space-between mt-5 w-100 pl-10 pr-10">
            <div className="match-history-button flex-center custom-box-shadow w-20 font-10">
              Urs
            </div>
            <div className="match-history-button flex-center custom-box-shadow w-20 font-10">
              Event name
            </div>
            <div className="match-history-button flex-center custom-box-shadow w-20 font-10">
              User
            </div>
            <div className="match-history-button flex-center custom-box-shadow w-20 font-10">
              Action
            </div>
          </div>
          <div className="hr-line mt-10"></div>
          <div className="upcoming-meet-scroll">
            {upcomingMeetingsData?.length > 0 &&
              upcomingMeetingsData?.map((data, index) => (
                <div key={index} className="flex-row">
                  <div className="flex-space-between mt-5 py-10 border-bottom-yellow">
                    <div className="w-25 font-12 flex-center ml-10">
                      {data.urs}
                    </div>
                    <div className="w-25 meetings-time font-12 padding-tb flex-start">
                      {data?.eventName}
                    </div>
                    <div className="w-25 flex-row flex-center">
                      <div className="font-12">
                        {data?.user[0]}
                        {data?.user?.length - 1 > 0
                          ? `+${data?.user?.length - 1}`
                          : ""}
                      </div>
                      <div className="down-arrow flex-center">
                        <BsArrowDown
                          onClick={() => handleUserDropdown(data?.user)}
                        />
                        {/* {activeUserDropdown && (
                          <div className="user-meetings-dropdown-container user-scroll w-30">
                            {dropDownUserData?.map((userObj, index) => {
                              return (
                                <div
                                  key={index}
                                  className="custom-box-shadow drop-down-menu font-12 mb-5 "
                                >
                                  {userObj}
                                </div>
                              );
                            })}
                          </div>
                        )} */}
                      </div>
                    </div>
                    <div className="w-15 flex-column flex-center">
                      <div
                        className="flex-center meetings-time-text join-button w-15 mr-10"
                        onClick={() => handleOpenJoinPopup(data)}
                      >
                        JOIN
                      </div>
                      {register_id == data.p_id && (
                        <div className="edit-icon mt-20">
                          <RiEditLine
                            onClick={() => handleOpenEditPopup(data)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="custom-box-shadow  meetings-details-div mt-10 p-10">
          <div className=" custom-box-shadow upcoming-meetings-div flex-start  font-16 w-60 p-10">
            Add New Meetings
          </div>
          <div className="hr-line mt-5"></div>
          <div className="flex-row flex-start mt-5">
            {meetingsType?.length > 0 &&
              meetingsType?.map((data, index) => {
                return (
                  <div
                    key={index}
                    className={
                      activeIndex === index
                        ? "custom-meetings-button custom-box-shadow font-size-12 flex-center fw-600 mr-10 no-border ml-10 mt-5 mb-5"
                        : "custom-meeetings-inactive-button custom-box-shadow font-size-12 flex-center fw-600 ml-10 mr-10 mt-5 mb-5"
                    }
                    onClick={() => handleMeetingTypeButton(index)}
                  >
                    {data}
                  </div>
                );
              })}
          </div>
          <div className="hr-line mt-5"></div>
          <div className="flex-column flex-space-around">
            <div className="meetings-name-button custom-box-shadow flex-start align-baseline mt-5 font-12">
              Meeting Name
            </div>
            {activeIndex === 0 && (
              <div className="w-100">
                <Select
                  className="w-100 mt-10 client-name-select"
                  classNamePrefix="react-select"
                  options={meetingOptionsList}
                  placeholder="Enter Meeting Name..."
                  value={meetingList}
                  onChange={handleMatchSelect}
                  isSearchable={true}
                  styles={customStyles}
                />
              </div>
            )}
            {activeIndex === 1 && (
              <input
                type="text"
                onChange={onChange}
                name="event_name"
                value={meetingInput?.event_name || ""}
                placeholder="Enter Meeting Name"
                className="flex-space-between flex-row custom-box-shadow settelment-time-div font-14 pl-10 w-100 mt-10 pr-10"
              />
            )}
          </div>
          <div className="flex-row flex-space-between mt-10">
            <div className="flex-column">
              <div className="meetings-name-button custom-box-shadow flex-start align-baseline mt-5 font-12">
                Meeting Date
              </div>
              <div className="management-div w-vw-40 font-12 flex-center custom-box-shadow mb-10 p-5 mt-10">
                <input
                  required
                  name="date"
                  type="date"
                  value={meetingInput?.date || ""}
                  onChange={onChange}
                  placeholder="Date"
                  className="flex-center w-80 match-input capital-text"
                />
                <FaCalendarDays className="font-size-20 pink-clr" />
              </div>
            </div>
            <div className="flex-column">
              <div className="meetings-name-button custom-box-shadow flex-start align-baseline mt-5 font-12">
                Meeting Time
              </div>
              <div className="management-div w-vw-40 font-12 flex-center custom-box-shadow mb-10 p-5 mt-10">
                <input
                  required
                  name="time"
                  type="time"
                  step="2"
                  value={meetingInput?.time || ""}
                  onChange={onChange}
                  placeholder="Time"
                  className="flex-center w-80 match-input"
                />
                <MdAccessTime className="font-size-20 pink-clr" />
              </div>
            </div>
          </div>
          <div className="flex-column flex-space-around">
            <div className="meetings-name-button custom-box-shadow flex-start align-baseline mt-5 font-12">
              Select Package
            </div>
            <div className="w-100">
              <Select
                className="w-100 mt-10 client-name-select"
                classNamePrefix="react-select"
                placeholder="Packages"
                options={adminPackages}
                value={selectedPackages}
                onChange={handlePackageSelection}
                closeMenuOnSelect={true}
                styles={customStyles}
              />
            </div>
          </div>
          <div className="flex-column flex-space-around">
            <div className="meetings-name-button custom-box-shadow flex-start align-baseline mt-10 font-12">
              Add Users
            </div>
            <div className="w-100 border-yellow">
              <Select
                className="w-100 mt-10 client-name-select"
                classNamePrefix="react-select"
                options={optionList}
                placeholder="Enter User Name..."
                value={selectedOptions}
                onChange={handleSelect}
                isSearchable={true}
                isMulti={true}
                closeMenuOnSelect={false}
                styles={customStyles}
              />
            </div>
          </div>
          {showUsersListDropdown && (
            <div className="select-team-dropdown h-auto w-100 font-12">
              {listOfUsers?.length > 0 &&
                listOfUsers?.map((obj, index) => (
                  <div
                    className="h-30p flex-center mb-5 "
                    key={index}
                    onClick={() => handleUsersListSelect(obj)}
                  >
                    {obj.user_name}
                  </div>
                ))}
            </div>
          )}
          <div className="flex-space-between flex-row mt-10">
            {filteredCallCreationList?.length > 0 &&
              filteredCallCreationList?.map((call, index) => {
                return (
                  <div
                    key={index}
                    className={
                      activeCallCreationIndex === index
                        ? "custom-call-creation-button custom-box-shadow no-border flex-center pink-clr fw-600 "
                        : "custom-call-creation-button custom-box-shadow flex-center clr-white fw-600"
                    }
                    onClick={() => handleCallCreateButton(index, call)}
                  >
                    {call}
                  </div>
                );
              })}
            {/* {callCreationList?.length > 0 &&
              callCreationList?.map((call, index) => {
                return (
                  <div
                    key={index}
                    className={
                      activeCallCreationIndex === index
                        ? "custom-call-creation-button custom-box-shadow no-border flex-center pink-clr fw-600 "
                        : "custom-call-creation-button custom-box-shadow flex-center clr-white fw-600"
                    }
                    onClick={() => handleCallCreateButton(index, call)}
                  >
                    {call}
                  </div>
                );
              })} */}
          </div>
          <button
            className="settelment-button button-box-shadow w-100 font-12 mt-20 mb-10 outline-none"
            onClick={() => handleSubmitButton()}
            disabled={isProcessing}
          >
            {isProcessing ? "isProcessing" : "Submit"}
          </button>
        </div>

        <div className="custom-box-shadow  meetings-details-div mt-10 p-10">
          <div className=" custom-box-shadow upcoming-meetings-div flex-start  font-16 w-60 p-10">
            UL New Meetings
          </div>
          <div className="hr-line mt-5"></div>
          <div className="flex-space-between mt-5 w-100 pr-5 pl-5">
            <div className="match-history-button flex-center custom-box-shadow font-12">
              UL
            </div>
            <div className="match-history-button flex-center custom-box-shadow font-12">
              Event name
            </div>
            <div className="match-history-button flex-center custom-box-shadow font-12">
              User
            </div>
            <div className="match-history-button flex-center custom-box-shadow font-12">
              Action
            </div>
          </div>
          <div className="hr-line mt-10"></div>
          <div className="upcoming-meet-scroll">
            {ulMeetingsData?.length > 0 &&
              ulMeetingsData
                ?.filter((obj) => {
                  const twentyFourHoursAgo = moment()
                    .subtract(24, "hours")
                    .valueOf();
                  return (
                    obj?.meetingUserIds?.includes(register_id) &&
                    obj?.recording_status !== "stopped"
                    // &&
                    // obj?.created_time_stamp >= twentyFourHoursAgo
                  );
                })
                ?.sort((a, b) => a?.created_time_stamp - b?.created_time_stamp)
                ?.map((data, index) => (
                  <div key={index} className="flex-column">
                    <div className="flex-space-between mt-5">
                      <div className="font-12 ml-15">{data.ul}</div>
                      <div className="meetings-time w-30 padding-tb ml-30">
                        {data.eventName}
                      </div>
                      <div className="font-12 w-20 ">{data.user}</div>
                      <div
                        className="flex-center meetings-time-text join-button w-15 mr-10"
                        onClick={() => handleOpenJoinPopup(data, true)}
                      >
                        JOIN
                      </div>
                    </div>
                    <div className="hr-line mt-5"></div>
                  </div>
                ))}
          </div>
        </div>
      </div>
      <FancyThankYouPopup
        openPopup={callManagementSubmitPopup}
        setclosePopup={setCallManagementSubmitPopup}
        displayData={UserDisplayData}
      />

      <SelectPackageType
        selectPackagePopup={selectPackagePopup}
        setSelectPackagePopup={setSelectPackagePopup}
      />

      <EditPopup
        openPopup={editPopup}
        setclosePopup={onEditClick}
        displayData={"Are You Sure You Want To Edit This Entry"}
      />
      <EditPopup
        openPopup={joinPopup}
        setclosePopup={setJoinPopup}
        displayData={"Are You Sure You Want To Join This Meeting"}
      />
      <CallJoinedUsers
        activeUserDropdown={activeUserDropdown}
        dropDownUserData={dropDownUserData}
        setActiveUserDropdown={setActiveUserDropdown}
      />
      {/* <SelectYourPackagePopup
        selectYourPackagePopup={selectYourPackagePopup}
        adminSubscription={adminSubscription}
        setSelectYourPackagePopup={setSelectYourPackagePopup}
        isProcessing={isProcessing}
        handleSubmitButton={() => handleSubmitButton()}
        onChange={(e) => onPackageSelection(e)}
      /> */}
    </div>
  );
}

export default CallManagement;
