import { IonModal, useIonToast } from "@ionic/react";
import { Images } from "../../images";
import { IoCloseSharp } from "react-icons/io5";

const QrCode = ({ openQrCode,setOpenQrCode }) => {
  return (
    <IonModal className="w-60 qrcode-payment" isOpen={openQrCode}>
        <div
          className="w-100 flex-flex-end p-5"
          onClick={() => setOpenQrCode(false)}
        >
          <IoCloseSharp />
        </div> 
      <div className="align-center flex-column">
        
        <div className="flex-center w-100">
          <img className="qr-code-payment" src={Images.qr_code}></img>
        </div>
      </div>
    </IonModal>
  );
};

export default QrCode;
