import React, { useState } from "react";
import {
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { IoCloseSharp } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa";
import { Images } from "../../images";
import { FiMinus } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { IoCloudUpload } from "react-icons/io5";
import FancyThankYouPopup from "../fancypopups/FancyThankYouPopup";
import QrCode from "./QrCode";

const BuyPackage = ({ buyPackage, setBuyPackage }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [userRoleDropDown, setUserRoleDropDown] = useState(false);
  const [userRole, setUserRole] = useState("Live Streaming Stock");
  const [selectPaymentMode, setSelectPaymentMode] = useState(
    "Select Payment Method"
  );
  const [paymentModeDropDown, setPaymentModeDropDown] = useState(false);
  const [openQrCode, setOpenQrCode] = useState(false);
  const handleOptionClick = (option) => {
    setUserRole(option);
    setUserRoleDropDown(false);
  };

  const handlePaymentClick = (option) => {
    setSelectPaymentMode(option);
    setPaymentModeDropDown(false);
    if(option==="QR CODE"){
      setOpenQrCode(true)
    }
  };

  const [quantities, setQuantity] = useState([
    {
      id: 1,
      LiveStream: "Live Stream",
      amount: "Amount",
      discount: "Discount",
      duration: "30 Days-25%",
      amountPrice: "4000",
      discountPrice: "1000",
      img: Images.Coin,
      quantity: 0,
    },
    {
      id: 2,
      LiveStream: "Live Stream",
      amount: "Amount",
      discount: "Discount",
      duration: "15 Days-25%",
      amountPrice: "12000",
      discountPrice: "1000",
      img: Images.SilverMedal,
      quantity: 0,
    },
    {
      LiveStream: "Live Stream",
      duration: "180 days-30%",
      img: Images.GoldMedal,
      amount: "Amount",
      amountPrice: 24000,
      discount: "Discoint",
      discountPrice: 1000,
      quantity: 0,
    },
  ]);
  const [proceed, setProceed] = useState(false);
  const [successUpdatePopup, setSuccessUpdatePopup] = useState(false);
  const handleQuantityChange = (id, change) => {
    setQuantity((prevQuantities) =>
      prevQuantities.map((item) =>
        item.id === id
          ? { ...item, quantity: Math.max(item.quantity + change, 0) }
          : item
      )
    );
  };

  const handleCloseBuyerPopup = () => {
    setBuyPackage(false);
    setSuccessUpdatePopup(true);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected file:", file);
    }
  };

  const packagesDate = [
    {
      live: "Live Stream",
      pkg: "Buying Pkgs x 10",
    },
    {
      live: "Live Stream",
      pkg: "Buying Pkgs x 10",
    },
    {
      live: "Live Stream",
      pkg: "Buying Pkgs x 10",
    },
  ];
  const TotalAmount = [
    {
      live: "Sub total",
      pkg: 120000,
    },
    {
      live: "Total Discount",
      pkg: 30000,
    },
    {
      live: "Total Amount",
      pkg: 90000,
    },
  ];


  return (
    <>
      {proceed === false && (
        <IonModal isOpen={buyPackage} className="buypackage-popup">
          <div onClick={() => setBuyPackage(false)} className="flex-flex-end">
            <IoCloseSharp onClick={() => setProceed(false)} />
          </div>

          <h5 className="text-center">Buy Packages</h5>
          <div className="text-center">
            Srinivas - <span className="clr-orng">super Admin</span>
          </div>
          <div
            className="clr-bg action-btn p-10 br-10 mt-10 d-flex flex-space-between"
            onClick={() => {
              setUserRoleDropDown(!userRoleDropDown);
            }}
          >
            {userRole} <FaAngleDown />
          </div>
          {userRoleDropDown && (
            <div className="select-stock action-btn action-btn-shadow  br-10 ">
              <div
                className="p-10"
                onClick={() => handleOptionClick("Live Streaming Stock")}
              >
                Live Streaming Stock
              </div>
              <div
                className="p-10"
                onClick={() => handleOptionClick("Line Package")}
              >
                Line Package
              </div>
            </div>
          )}
          {quantities.map((item) => (
            <div className="buyPackageContainer br-10 mt-10 p-3" key={item.id}>
              <IonRow>
                <IonCol size="4" className="clr-green">
                  {userRole === "Line Package" ? "Line Package" : "Live Stream"}
                </IonCol>
                <IonCol size="4">{item.amount}</IonCol>
                <IonCol size="4">{item.discount}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="4">{item.duration}</IonCol>
                <IonCol size="4">{item.amountPrice}</IonCol>
                <IonCol size="4">{item.discountPrice}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="4">
                  <img
                    src={item.img}
                    alt="Gold icon"
                    className="packageicon-buy"
                  />
                </IonCol>
                <IonCol size="4"></IonCol>
                <IonCol size="4" className="btn-shadow br-10">
                  <div className="d-flex flex-center flex-space-between">
                    {item.quantity === 0 ? (
                      <>
                        <div
                          fill="clear"
                          onClick={() => handleQuantityChange(item.id, -1)}
                          className="quantity-btn"
                        >
                          <FiMinus />
                        </div>
                        <span className="quantity-value">Add</span>
                        <div
                          fill="clear"
                          onClick={() => handleQuantityChange(item.id, 1)}
                          className="quantity-btn"
                        >
                          <IoMdAdd />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          fill="clear"
                          onClick={() => handleQuantityChange(item.id, -1)}
                          className="quantity-btn"
                        >
                          <FiMinus />
                        </div>
                        <span className="quantity-value">{item.quantity}</span>
                        <div
                          fill="clear"
                          onClick={() => handleQuantityChange(item.id, 1)}
                          className="quantity-btn"
                        >
                          <IoMdAdd />
                        </div>
                      </>
                    )}
                  </div>
                </IonCol>
              </IonRow>
            </div>
          ))}

          <IonRow className="m-5">
            <IonCol size="4">Total Amount</IonCol>
            <IonCol size="2"></IonCol>
            <IonCol
              size="6"
              className="proceed-btn br-10 mt-10 text-center clr-green"
              onClick={() => setProceed(true)}
            >
              Proceed
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4" className="m-5 font-weight-600">
              {quantities.reduce(
                (total, item) => total + item.amountPrice * item.quantity,
                0
              )}
            </IonCol>
          </IonRow>
        </IonModal>
      )}
      {proceed === true && (
        <IonModal isOpen={buyPackage} className="buypackage-popup">
          <div onClick={() => setBuyPackage(false)} className="flex-flex-end">
            <IoCloseSharp />
          </div>

          <h5 className="text-center">Package Details</h5>
          <div className="buyPackageContainer br-10 p-5">
            {packagesDate.map((item, index) => (
              <div className="flex-space-between mt-10 white-border">
                <div>
                  <span className="clr-green">{item.live}</span> - 30 Days
                </div>
                <div>{item.pkg}</div>
              </div>
            ))}
          </div>
          <div className="buyPackageContainer br-10 mt-10  p-5">
            {TotalAmount.map((item, index) => (
              <div className="flex-space-between mt-10 ">
                <div>
                  <span className="clr-white">{item.live}</span>
                </div>
                <div>{item.pkg}</div>
              </div>
            ))}
          </div>
          <div className="payment-gateway mt-10 br-10 flex-center clr-green">
            Select Payment Gateway
          </div>
          <div
            className="payment-gateway  mt-10 br-10 flex-space-between p-5 "
            onClick={() => setPaymentModeDropDown(!paymentModeDropDown)}
          >
            {selectPaymentMode} <FaChevronDown />
            {paymentModeDropDown && (
              <div className="select-payment-mode overflow-scroll action-btn action-btn-shadow  br-10 ">
                <div
                  className="p-10"
                  onClick={() => handlePaymentClick("NEFT/RTGS")}
                >
                  NEFT/RTGS
                </div>
                <div className="p-10" onClick={() => handlePaymentClick("UPI")}>
                  UPI
                </div>
                <div
                  className="p-10"
                  onClick={() => handlePaymentClick("BANK ACCOUNT")}
                >
                  BANK ACCOUNT
                </div>
                <div
                  className="p-10"
                  onClick={() => handlePaymentClick("QR CODE")}
                >
                  QR CODE
                </div>
              </div>
            )}
          </div>

          {selectPaymentMode === "BANK ACCOUNT" && (
            <div className="payment-gateway mt-5  br-10  p-3 ">
              <IonRow>
                <IonCol size="6" className="clr-white">
                  Name:Jayantha
                </IonCol>
                <IonCol size="6">Ifsc:SBIN000111</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">A/C No:12345678</IonCol>
                <IonCol size="6">Bank:SBI</IonCol>
              </IonRow>
            </div>
          )}
          {selectPaymentMode === "UPI" && (
            <div className="payment-gateway mt-5  br-10  p-3 flex-center">
              we2call@Ybl
            </div>
          )}

          <div className="payment-gateway mt-10 br-10 flex-space-between p-5">
            <label
              htmlFor="screenshot-upload"
              className="upload-label flex-space-between w-100"
            >
              Upload Screenshot
              <IoCloudUpload className="pink-blue-tex action-btn-shadow" />
            </label>
            <input
              type="file"
              id="screenshot-upload"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleFileUpload(e)}
            />
          </div>
          <div className="payment-gateway mt-10 br-10 flex-center clr-green">
           
            <input
              type="text"
              id="utrNumber"
              name="utrNumber"
              className="date-none w-100 p-5"
              placeholder="Enter UTR Number"
            />
          </div>

          <div
            className="payment-gateway mt-10 br-10 flex-center clr-green "
            onClick={handleCloseBuyerPopup}
          >
            Pay
          </div>
        </IonModal>
      )}
      <FancyThankYouPopup
        openPopup={successUpdatePopup}
        setclosePopup={setSuccessUpdatePopup}
        displayData={"Payment Was SuccessFul"}
      />
      <QrCode openQrCode={openQrCode} setOpenQrCode={setOpenQrCode}/>
    </>
  );
};

export default BuyPackage;
