import React, { useState } from "react";
import {
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa";
import FancyThankYouPopup from "../fancypopups/FancyThankYouPopup";

const AddbuyerPopup = ({ buyerPopup, setBuyerPopup }) => {
  const [showModal, setShowModal] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [userRole, setUserRole] = useState("Select Role");
  const [userRoleDropDown, setUserRoleDropDown] = useState(false);
  const [userName, setUserName] = useState("Select Name");
  const [userNameDropDown, setUserNameDropDown] = useState(false);
  const [successUpdatePopup, setSuccessUpdatePopup] = useState(false);
  const [days, setDays] = useState([
    { id: 1, duration: "30 Days", value: "" },
    { id: 2, duration: "60 Days", value: "" },
    { id: 3, duration: "90 Days", value: "" },
  ]);
  const handleOptionClick = (option) => {
    setUserRole(option);
    setUserRoleDropDown(false);
    setUserNameDropDown(false);
  };
  const handleUserNameClick = (option) => {
    setUserName(option);
    setUserNameDropDown(false);
    setUserRoleDropDown(false);
  };

  const handleCloseBuyerPopup = () => {
    setBuyerPopup(false);
    setSuccessUpdatePopup(true);
  };

  const handlepasswordicon = () => {
    setPasswordVisible(!passwordVisible);
  };

 
  const handleInputChange = (id, inputValue) => {
    if (!/^\d*$/.test(inputValue)) return; // Allow only numeric input
    const value = parseFloat(inputValue);
    setDays((prevDays) =>
      prevDays.map((item) =>
        item.id === id
          ? { ...item, value: value >= 0 && value < 100 ? inputValue : item.value }
          : item
      )
    );
  };

  return (
    <>
      <IonModal isOpen={buyerPopup} className="buyer-popup">
        <div onClick={() => setBuyerPopup(false)} className="flex-flex-end">
          <IoCloseSharp />
        </div>

        <h5 className="text-center">Add Buyers</h5>
        <div
          className="clr-bg action-btn p-10 br-10 mt-10 d-flex flex-space-between "
          onClick={() => {
            setUserRoleDropDown(!userRoleDropDown);
            setUserNameDropDown(false);
          }}
        >
          {userRole} <FaAngleDown />
        </div>
        {userRoleDropDown && (
          <div className="select-role action-btn action-btn-shadow  br-10 ">
            <div className="p-10" onClick={() => handleOptionClick("Director")}>
              Director
            </div>
            <div className="p-10" onClick={() => handleOptionClick("Agent")}>
              Agent
            </div>
          </div>
        )}
        <div
          className="clr-bg action-btn p-10 br-10  mt-10 d-flex flex-space-between"
          onClick={() => setUserNameDropDown(!userNameDropDown)}
        >
          {userName} <FaAngleDown />
        </div>

        {userNameDropDown && (
          <div className="select-Name action-btn action-btn-shadow br-10">
            <div
              className="p-10"
              onClick={() => handleUserNameClick("Sangram")}
            >
              Sangram
            </div>
            <div
              className="p-10"
              onClick={() => handleUserNameClick("Ranjith")}
            >
              Ranjith
            </div>
          </div>
        )}
        <div className="w-100 mt-10">
          Live Streaming -Discount
          <div className="d-flex flex-space-between mt-10 ">
            {days.map((item, index) => (
              <div className="p-10 br-10 action-btn font-13 action-btn-shadow w-30  d-flex">
                <span className="w-70 right-border"> {item.duration}</span>

                <input
            type="text"
            placeholder="%"
            className="date-none w-30"
            // value={item.value}
            // onChange={(e) => handleInputChange(item.id, e.target.value)}
            maxLength={2}
          />
              </div>
            ))}
          </div>
        </div>
        <div className="w-100 mt-10">
          Line -Discount
          <div className="d-flex flex-space-between mt-10">
      {days.map((item) => (
        <div
          key={item.id}
          className="p-10 br-10 action-btn font-13 action-btn-shadow w-30 d-flex"
        >
          <span className="w-70 right-border">{item.duration}</span>
          <input
            type="text"
            placeholder="%"
            className="date-none w-30"
            // value={item.value}
            // onChange={(e) => handleInputChange(item.id, e.target.value)}
            maxLength={2}
          />
        </div>
      ))}
    </div>
        </div>
        <div>
          <input
            type={passwordVisible ? "text" : "password"}
            className="clr-bg action-btn p-10 br-10  mt-10 w-100"
            placeholder="Enter Admin Password"
          />
          <span
            onClick={handlepasswordicon}
            style={{
              position: "absolute",
              right: "25px",
              top: "78%",
              outline: "none",
              border: "none",
            }}
          >
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <div
          className="clr-bg action-btn p-10 br-10 clr-green flex-center mt-10 "
          onClick={handleCloseBuyerPopup}
        >
          Add
        </div>
      </IonModal>
      <FancyThankYouPopup
        openPopup={successUpdatePopup}
        setclosePopup={setSuccessUpdatePopup}
        displayData={"Buyer Added  Succesfully"}
      />
    </>
  );
};

export default AddbuyerPopup;
